<script setup>
import { storeToRefs } from 'pinia';
import Illustration1 from 'assets/illustrations/illu-01.svg?url';
import { useMessageStore } from '~/stores/messages';

// Fetch messages
const messagesStore = useMessageStore();
const { messages: staticKeys } = storeToRefs(messagesStore);

onUnmounted(() => {
    if (localStorage.getItem('showSupportNote')) {
        localStorage.removeItem('showSupportNote')
    }
});

// Markdown
const { $md } = useNuxtApp();
const errorTxt = staticKeys.value?.general_error_no_salesforcedata_markdown ? $md.renderInline(staticKeys.value?.general_error_no_salesforcedata_markdown) : '';
</script>

<template>
    <SideBarWrapper class="support-note-container">
        <SideBarHeader>
            <SideBarMainHeadline :text="staticKeys?.general_error_headline" />
            <p class="sidebar-support-text" v-html=" errorTxt" />
        </SideBarHeader>

        <img :src="Illustration1" :alt="staticKeys?.general_error_headline">
    </SideBarWrapper>
</template>

<style lang="scss">
.support-note-container {
    display: flex;
    flex-flow: row wrap;
    height: 100%;

    .side-bar-header {
        width: 100%;
    }

    img {
        width: 100%;
        margin-top: auto;
    }
}

.sidebar-support-text {
    @include body1-medium;

    a {
        color: inherit;
        text-decoration: underline;
    }
}
</style>
